<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader title="Users" />

    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <UsersNavigation @searchQuery="searchQuery" />
      <PaginationResults
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @searchQuery="searchQuery"
      />
      <div class="row">
        <div class="col">
          <UsersList :users="users" />
        </div>
      </div>
      <Pagination
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>

    <Modal id="modal-user-create">
      <template v-slot:title>Create User</template>

      <template v-slot:body>
        <UserForm modal="#modal-user-create" @complete="fetchData()" />
      </template>
    </Modal>
  </div>
</template>

<script>
import Error from '../components/Error';
import hasTimeDate from '../mixins/hasTimeDate';
import Loading from '../components/Loading';
import Modal from '../components/Modal';
import PageHeader from '../components/PageHeader';
import Pagination from '../components/Pagination';
import PaginationResults from '../components/PaginationResults';
import paginationFunctions from '../mixins/paginationFunctions';
import UserForm from '../components/forms/UserForm';
import UsersList from '../components/users/UsersList';
import UsersNavigation from '../components/users/UsersNavigation';

export default {
  name: 'UsersIndex',
  mixins: [hasTimeDate, paginationFunctions],
  components: {
    Error,
    Loading,
    Modal,
    PageHeader,
    Pagination,
    PaginationResults,
    UserForm,
    UsersList,
    UsersNavigation,
  },
  data() {
    return {
      error: null,
      isLoading: false,
      pageNum: Number(this.$route.query.page) || 1,
      perPage: Number(this.$route.query.per_page) || 12,
    };
  },
  computed: {
    users() {
      return this.$store.getters['users/getAll'];
    },
    hasUsers() {
      return this.users.length > 0;
    },
    meta() {
      return this.$store.getters['users/getAllMeta'];
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
    dates() {
      return this.$store.getters['search/getRange'];
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  unmounted() {
    // console.log('UsersIndex unmounted()');
    this.$store.commit('search/clearSearch');
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
      };

      const payload = {
        params: Object.assign(defaultParams, params || {}),
      };

      if (this.q) {
        payload.params.q = this.q;
      }

      // Check for limits imposed by a campaign, promotion, or manually set date range
      const dateLimits = this.getDateLimits();
      if (dateLimits) {
        payload.params.timeslice = dateLimits;
      }

      // console.log('UsersIndex methods fetchData() :payload', payload);

      this.$store
        .dispatch('users/getAll', payload)
        .then(() => {
          // console.log('UsersIndex methods fetchData() getAll then');
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    getDateLimits() {
      // Default to no limits;
      let limits = false;
      let start = false;
      let end = false;

      if (this.dates) {
        // Start with the most specific, manually set dates in the search bar. This takes precedence over all others
        ({ start, end } = this.dates);
      }

      if (start && end) {
        // If we have values for the date range, set them and pass back to the caller
        limits = `created_at;${this.formatISODate(start)}|${this.formatISODate(end)}`;
      }

      return limits;
    },
    searchQuery() {
      // console.log('UsersIndex methods searchQuery()');
      this.pageNum = 1;

      this.fetchData();
    },
  },
};
</script>

<style scoped lang="scss"></style>
