<template>
  <form @submit.prevent="handleSubmit">
    <FormError v-if="error" :error="error" />
    <fieldset :disabled="isLocked">
      <div class="form-row">
        <div class="col-6">
          <div class="form-group">
            <label for="u-first">First Name</label>
            <input
              type="text"
              class="form-control"
              id="u-first"
              name="first"
              maxlength="50"
              aria-describedby="firstHelp"
              v-model="user.first"
            />
            <small id="firstHelp" class="form-text text-muted">The user's first name.</small>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="u-last">Last Name</label>
            <input
              type="text"
              class="form-control"
              id="u-last"
              name="last"
              maxlength="50"
              aria-describedby="lastHelp"
              v-model="user.last"
            />
            <small id="lastHelp" class="form-text text-muted">The user's last name.</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="u-org">Organization <span class="text-danger">*</span></label>
        <select
          class="form-control"
          id="u-org"
          name="org"
          aria-describedby="orgHelp"
          required
          v-model="user.org"
        >
          <option readonly>- Select -</option>
          <option value="wgts">WGTS</option>
          <option value="atmo">Atmosphere</option>
        </select>
        <small id="orgHelp" class="form-text text-muted">
          The Organization that the user belongs to.
        </small>
      </div>

      <div class="form-row">
        <div class="col-6">
          <div class="form-group">
            <label for="u-email">Email <span class="text-danger">*</span></label>
            <input
              class="form-control"
              id="u-email"
              name="email"
              aria-describedby="emailHelp"
              v-model="user.email"
            />
            <small id="emailHelp" class="form-text text-muted">The user's email address.</small>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="u-phone">Phone</label>
            <input
              type="text"
              class="form-control"
              id="u-phone"
              name="phone"
              aria-describedby="phoneHelp"
              v-model="user.phone"
            />
            <small id="phoneHelp" class="form-text text-muted">The user's phone number.</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="u-password">Password</label>
        <div class="input-group">
          <input
            :type="showPassword ? `text` : `password`"
            class="form-control"
            id="u-password"
            name="password"
            aria-describedby="passwordHelp"
            v-model="user.password"
            minlength="8"
            maxlength="20"
          />
          <div class="input-group-append">
            <button
              :class="{
                btn: true,
                'btn-outline-dark': !showPassword,
                'btn-dark': showPassword,
              }"
              type="button"
              @click="showPassword = !showPassword"
            >
              <font-awesome-icon v-if="showPassword" icon="eye" />
              <font-awesome-icon v-else icon="eye-slash" />
            </button>
          </div>
        </div>
        <small id="passwordHelp" class="form-text text-muted">
          Must be between 8-20 characters.
        </small>
      </div>

      <div class="form-row">
        <div class="col-5">
          <div class="form-group">
            <label for="u-city">City <span class="text-danger">*</span></label>
            <input
              class="form-control"
              id="u-city"
              name="city"
              aria-describedby="cityHelp"
              v-model="user.city"
              required
            />
            <small id="cityHelp" class="form-text text-muted">The user's city.</small>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label for="u-state">State</label>
            <select class="form-control" id="u-state" name="state" required v-model="user.state">
              <option value="" readonly>- Select -</option>
              <option v-for="(state, index) in states" :key="`state-${index}`" :value="state.code">
                {{ state.code }}
              </option>
            </select>
            <small id="stateHelp" class="form-text text-muted">The user's state.</small>
          </div>
        </div>
        <div class="col-5">
          <div class="form-group">
            <label for="u-country">Country <span class="text-danger">*</span></label>
            <select
              class="form-control"
              id="u-country"
              name="country"
              aria-describedby="countryHelp"
              required
              v-model="user.country"
            >
              <option readonly>- Select -</option>
              <option
                v-for="(country, index) in countries"
                :key="`country-${index}`"
                :value="country.code"
              >
                {{ country.name }}
              </option>
            </select>
            <small id="countryHelp" class="form-text text-muted">The user's country.</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="u-timezone">Timezone</label>
        <select
          class="form-control"
          id="u-timezone"
          name="timezone"
          aria-describedby="timezoneHelp"
          required
          v-model="user.timezone"
        >
          <option readonly>- Select -</option>
          <option
            v-for="(timezone, index) in timezones"
            :key="`timezone-${index}`"
            :value="timezone.name"
          >
            {{ cleanTimezone(timezone.name) }}
          </option>
        </select>
        <small id="timezoneHelp" class="form-text text-muted">The user's timezone.</small>
      </div>
    </fieldset>

    <div v-if="error" class="alert alert-danger">
      {{ error }}
    </div>

    <div class="form-row justify-content-between">
      <button type="submit" class="btn btn-success" :disabled="isLocked">
        <font-awesome-icon icon="save" /> Save
      </button>
      <button type="button" class="btn btn-outline-danger" :disabled="isLocked" @click="modalClose">
        <font-awesome-icon icon="window-close" /> Cancel
      </button>
    </div>
  </form>
</template>

<script>
/*global $*/
import helpers from '../../mixins/helpers';
import formHelpers from '../../mixins/formHelpers';
import FormError from './FormError';

export default {
  name: 'UserForm',
  mixins: [formHelpers, helpers],
  components: { FormError },
  props: {
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    modal: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      user: this.initializeUser(),
      error: null,
      isLocked: false,
      allowedFields: [
        'org',
        'first',
        'last',
        'email',
        'password',
        'phone',
        'timezone',
        'city',
        'state',
        'country',
      ],
      showPassword: false,
    };
  },
  methods: {
    cleanTimezone(timezone) {
      return timezone.replace('_', ' ');
    },
    initializeUser() {
      return Object.assign(
        {
          org: 'wgts',
          first: '',
          last: '',
          email: '',
          password: '',
          phone: '',
          timezone: 'America/New_York',
          city: 'Baltimore',
          state: 'MD',
          country: 'US',
        },
        this.initial
      );
    },
    handleSubmit() {
      console.log('UserForm handleSubmit()', this.user);
      // Lock the buttons
      this.error = null;
      this.isLocked = true;

      const payload = {
        formData: this.toFormData(this.prepareData()),
        options: {
          method: 'POST',
        },
      };

      // Set the URL to either create or update
      let action;
      if (this.isNew()) {
        action = 'users/create';
        // payload.options.method = 'POST';
      } else {
        action = 'users/update';
        payload.options.id = this.user.user_id;
        // We are setting 'PUT' here because we are replacing the entire object. Future iterations may only adjust
        // some parts of the model.
        payload.options.method = 'PUT';
      }

      // Send to action
      this.$store
        .dispatch(action, payload)
        .then((response) => {
          // on success, close modal, unlock buttons
          console.log('UserForm handleSubmit() then', response);
          // #TODO store the Promotion response into the data.promotion
          this.user.user_id = response.data.data.user_id;
          this.$emit('complete');
          this.modalClose();
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('UserForm handleSubmit() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
    modalClose() {
      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
      }
    },
    prepareData() {
      return this.user;
    },
    unlockForm() {
      this.isLocked = false;
    },
  },
};
</script>
