<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <button
      v-if="canByRole(['admin', 'developer'])"
      class="btn btn-success mr-auto"
      type="button"
      data-toggle="modal"
      data-target="#modal-user-create"
    >
      <font-awesome-icon icon="plus-circle" /> Create User
    </button>
    <SearchForm model="user" :use-search="true" @searchQuery="searchQuery" />
  </nav>
</template>

<script>
import SearchForm from '../forms/SearchForm';
import search from '../../mixins/hasSearch';

export default {
  name: 'UsersNavigation',
  mixins: [search],
  components: { SearchForm },
};
</script>
