<template>
  <table
    :class="{
      'users-list': true,
      table: true,
      'table-striped': striped && hasUsers,
      'table-hover': hover,
    }"
  >
    <thead class="thead-dark">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Name</th>
        <th scope="col">Organization</th>
        <th scope="col">Roles</th>
        <th scope="col">Email</th>
        <th scope="col">Last Activity At</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!hasUsers">
        <td colspan="5">There are no users to display.</td>
      </tr>
      <UsersListRow v-else v-for="user in users" :key="user.user_id" :user="user" />
    </tbody>
  </table>
</template>

<script>
import UsersListRow from '../users/UsersListRow';

export default {
  name: 'UsersList',
  components: { UsersListRow },
  props: {
    users: {
      type: [Object, Array],
      default: () => [],
      required: true,
    },
    striped: {
      type: Boolean,
      default: false,
      required: false,
    },
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    hasUsers() {
      return this.users.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
// .users-list {
//   tbody {
//     tr {
//       cursor: pointer;
//     }
//   }
// }
</style>
