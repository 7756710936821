<template>
  <tr>
    <td>
      <router-link :to="{ name: 'user', params: { id: user.user_id } }">
        {{ user.user_id }}
      </router-link>
    </td>
    <td>
      <router-link :to="{ name: 'user', params: { id: user.user_id } }">
        {{ fullName }}
      </router-link>
    </td>
    <td>{{ user.org }}</td>
    <td>{{ roles }}</td>
    <td>{{ user.email }}</td>
    <td>{{ shortDateTime(user.last_activity_at) }}</td>
  </tr>
</template>

<script>
import hasTimeDate from '../../mixins/hasTimeDate';
// import hasUser from '../../mixins/hasUser';
import helpers from '../../mixins/helpers';

export default {
  name: 'UsersListRow',
  mixins: [hasTimeDate, helpers],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    fullName() {
      if (this.user.display_as) {
        return this.user.display_as;
      }

      return [this.user.first, this.user.last].join(' ');
    },
    roles() {
      return this.stringify(this.user.roles, 'name', ', ');
    },
  },
};
</script>
